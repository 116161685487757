<template>
  <div>
    <v-row justify="space-between">
      <h3 class="title white--text">{{ displayTitle }}</h3>

      <div>
        <g-button label="Return" @onclick="$router.push({ name: 'admin_lists' })" />
        <save-button :onsave="saveList" />
      </div>
    </v-row>

    <v-card>
      <v-row class="pa-2">
        <v-col cols="3">
          <AddListItemDialog :existingKeys="syncedList" @add="addItem" />
        </v-col>
        <v-col>
          <span class="subtitle-2">Environments</span>
        </v-col>
        <v-col cols="1">
          <span class="subtitle-2">Remove *</span>
        </v-col>
      </v-row>

      <v-row v-for="item in displayList" :key="item.key" class="hover">
        <v-col cols="3" class="subtitle-2 px-5" align-self="center">
          {{ item.label }}
        </v-col>
        <v-col>
          <f-dropdown
            multiple
            :items="environments"
            :value="item.environments"
            @input="(v) => (syncedList[item.key] = v)"
          />
        </v-col>
        <v-col cols="1">
          <icon-btn icon="delete" @onclick="removeItem(item.key)" />
        </v-col>
      </v-row>
      <v-alert dense text class="caption">
        * This list syncs with the database on refresh. Deleted items will be readded if they are assigned to a
        supplier/product/etc. To permenently delete it, you must update the database item directly.
      </v-alert>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import OPTION_LIST_CONFIG from "@/config/OPTION_LIST_CONFIG";
import ENV from "@/config/ENV";
import { AddListItemDialog } from "@/modules/admin.lists";
export default {
  components: {
    AddListItemDialog,
  },
  data() {
    return {
      dialog: false,
      pendingAddItem: null,

      environments: ENV,
      syncedList: {},
      originalListCopy: "",
    };
  },
  computed: {
    refkey() {
      return this.$route.params.list;
    },
    displayTitle() {
      return OPTION_LIST_CONFIG[this.refkey].label;
    },
    displayList() {
      return Object.keys(this.syncedList)
        .map((key) => {
          return {
            key: key,
            label: key,
            environments: this.syncedList[key],
          };
        })
        .sort((a, b) => {
          if (a.key < b.key) return -1;
          if (a.key > b.key) return 1;
          return 0;
        });
    },
  },
  methods: {
    removeItem(key) {
      Vue.delete(this.syncedList, key);
    },
    addItem(pending) {
      Vue.set(this.syncedList, pending, []);
    },
    saveList() {
      return this.$store
        .dispatch("ListStore/updateOptionList", {
          refkey: this.refkey,
          data: this.syncedList,
        })
        .then((_) => {
          // Update local stored list
          this.$store.dispatch("ListStore/getOptionList", this.refkey);
        });
    },
  },
  mounted() {
    // Sync list with anything in DB
    // (Sometimes external lists are added, etc)
    this.$store
      .dispatch("ListStore/getAndSyncOptionList", this.refkey)
      .then((list) => {
        this.syncedList = list;
        this.originalListCopy = JSON.stringify(list);
      })
      .catch((err) => {
        this.$root.$error(err);
      });
  },
};
</script>
